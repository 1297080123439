export const price = {
  "content": {
    "head": [
      "#",
      "Наименование работ",
      "Цена за единицу (руб.)"
    ],
    "body": [
      {
        "head": "Ремонт кинескопных, Плазма, ЖК-телевизоров цветного изображения, в том числе мониторов",
        "lines": [
          "Телевизор с CRT-кинескопом от 14' до 21' дюймов.|900",
          "Телевизор с CRT-кинескопом от 25' до 29' дюймов.|1300",
          "ЖК-телевизор от 15' до 24' дюймов.|1400",
          "ЖК-телевизор от 26' до 29' дюймов|2100",
          "ЖК-телевизор от 32' до 39’дюймов.|2700",
          "ЖК-телевизор от 40' до 42' дюймов.|3300",
          "ЖК-телевизор от 43' до 49' дюймов.|3400",
          "ЖК-телевизор от 50' до 60' дюймов.|4500",
          "ЖК-телевизор от 60’ дюймов и выше.|6000",
          "Телевизор PLAZMA.|3500",
          "Монитор ЖК от 15’ до 24’ дюймов.|1500",
          "Монитор ЖК от 27’ до 30’ дюймов.|2200",
          "Монитор ЖК от 31’ до 39’ дюймов.|2800"
        ]
      },
      {
        "head": "Ремонт крупногабаритной бытовой техники",
        "lines": [
          "Стиральных машин с выездом на дом к клиенту до 10 км.|2000",
          "Техническое обслуживание стиральных машин с выездом на дом к клиенту до 10 км.|1100",
          "Техническое обслуживание стиральных машин в помещении СЦ.|900",
          "Ремонт холодильников без заправки хладагентом с выездом на дом к клиенту до 10 км или в помещении СЦ.|2200",
          "Ремонт холодильников с заменой хладагента с выездом на дом к клиенту до 10 км или в помещении СЦ.|6800",
          "Техническое обслуживание холодильника: чистка системы, сушка азотом.|1000",
          "выезд к клиенту на дом до 10 км с установленным фактом отсутствия заявленного дефекта по результатам диагностики для стиральных машин и холодильников.|1000"
        ]
      },
      {
        "head": "Ремонт мелкобытовой техники",
        "lines": [
          "Микроволновая печь.|900",
          "Хлебопечка.|900",
          "Пылесос проводной.|900",
          "Пылесос-робот, вертикальный или беспроводной|1500",
          "Кофе-машина с электронным управлением.|2200",
          "Кофе-машина капсюльная.|1300",
          "Кофеварка.|1300",
          "Электробритва, машинки для стрижки, эпилятор, миксер, блендер, фен, стайлер, тостер, пароварка, чайник,  кофемолка, утюг, увлажнитель.|900",
          "Мультиварка.|1200",
          "Электрическая мясорубка.|1200",
          "Утюг с парогенератором.|1500",
          "Отпариватель.|1200",
          "Электрогриль.|1500"
        ]
      },
      {
        "head": "Ремонт аудио-видео техники",
        "lines": [
          "ТВ-ресиверы.|1200",
          "Цифровые ТВ-приставки.|600",
          "Домашние кинотеатры.|1500",
          "Ремонт пультов дистанционного управления.|450"
        ]
      },
      {
        "head": "Диагностические работы и сопровождение",
        "lines": [
          "Определение ремонтопригодности  бытовой техники, кроме холодильников и стиральных машин.|300",
          "Определение ремонтопригодности холодильников.|700",
          "Определение ремонтопригодности стиральных машин.|400",
          "Оформление акта технического состояния.|300"

        ]
      }
    ]
  }
}
import React from 'react';
import './styles.css';
import { price } from './price';

export const ListTableW = () => {

  const renderHead = (): React.ReactElement => {
    const  head  = price.content.head;

    return (
      <tr className="head">
        <td className="leftW">
          <h1 className="x">{head[0]}</h1>
        </td>
        <td className="centerW">
          <h1 className="x">{head[1]}</h1>
        </td>
        <td className="rightW">
          <h1 className="x">{head[2]}</h1>
        </td>
      </tr>
    )
  }

  const renderLines = (): React.ReactElement[] => {

    const  body  = price.content.body;
    const final: React.ReactElement[] = [];
    // eslint-disable-next-line
    body.map((item, index): void => {
      const head = item.head;
      if (head) {
        final.push(
          <tr className="head" key={index + head}>
            <td className="leftW"></td>
            <td className="centerW">
              <h1 className="x">{head}</h1>
            </td>
            <td className="rightW"></td>
          </tr>
        )
      }

      const lines = item.lines;
      // eslint-disable-next-line
      lines.map((item, index: number): void => {
        const fa = item.split('|');
        final.push(
          <tr className="rowt" key={index + fa[0]}>
            <td className="leftW">
              <h2 className="x" style={{ fontWeight: 'bold' }}>{index + 1 + '.'}</h2>
            </td>
            <td className="centerW">
              <h2 className="x">{fa[0]}</h2>
            </td>
            <td className="rightW">
              <h2 className="x" style={{ fontWeight: 'bold' }}>{fa[1]}</h2>
            </td>
          </tr>
        )
      })
    })

    return final;
  }

  return (
    <div>
      <table>
        <thead>
          {renderHead()}
        </thead>
        <tbody>
          {renderLines()}
        </tbody>
        <tfoot />
      </table>
      <br />
      <p className="textW">
        {`Примечание: все цены указаны без стоимости запчастей. 
      При выполнении (по желанию заказчика) срочного ремонта и технического обслуживания аппаратуры (при выполнении ремонта в день приема заказа) 
      к ценам прейскуранта применяется надбавка в размере 40%. В случае приема аппаратуры в ремонт со следами неквалифицированного 
      вмешательства и с измененной конструкцией, а также аппаратуры, подвергшейся воздействию грозового разряда, 
      либо имеющей повреждения печатных плат в виде трещин, разломов в результате механического воздействия (удара) 
      или коррозии элементов, вызванной попаданием жидкости во внутрь аппарата, к ценам прейскуранта применяется надбавка 
      в размере 40%. В случае проведения ремонта, связанного с разборкой узлов вращения на стиральных машинах к 
      ценам прейскуранта применяется надбавка в размере 40%. Стоимость технического обслуживания теле-видео-аудио аппаратуры 
      (настройка, обновление ПО, разблокировка, чистка) составляет 20% от стоимости ремонта по прейскуранту применительно к каждой категории бытовой техники. 
      При заключении договора на ремонт с юридическими лицами к сумме прейскуранта применяется коэффициент 1,5. При осуществлении выезда к клиенту свыше 20 км, 
      к ценам прейскуранта добавляется дополнительный тариф, согласно приведенной ниже таблицы:`}
      </p>
      <br />
      <table>
        <thead>
          <tr className="head">
            <td className="centerAddW">
              <h1 className="x">Расстояние (км.)</h1>
            </td>
            <td className="centerAddW">
              <h1 className="x">Стоимость (руб.)</h1>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr className="rowt">
            <td className="centerAddW"><h2 className="x" style={{fontWeight: 'bold'}}>10 - 20</h2></td>
            <td className="centerAddW"><h2 className="x" style={{fontWeight: 'bold'}}>500</h2></td>
          </tr>
          <tr className="rowt">
            <td className="centerAddW"><h2 className="x" style={{fontWeight: 'bold'}}>20 - 50</h2></td>
            <td className="centerAddW"><h2 className="x" style={{fontWeight: 'bold'}}>1000</h2></td>
          </tr>
          <tr className="rowt">
            <td className="centerAddW"><h2 className="x" style={{fontWeight: 'bold'}}>50 - 80</h2></td>
            <td className="centerAddW"><h2 className="x" style={{fontWeight: 'bold'}}>2000</h2></td>
          </tr>
        </tbody>
        <tfoot />
      </table>
      <br />
      <p className="textW">{`Внимание клиента: важно знать, что в случае проведения ремонта или технического обслуживания к 
      стоимости прейскуранта добавляется определение ремонтопригодности по категориям бытовой техники.`}
        <br /><br />
        <a href="assets/price.doc">Скачать прайс-лист в формате *.doc можно по этой ссылке.</a>
      </p>
    </div>
  )
}
import React, {  useCallback, useEffect } from "react";
import { Block } from './block/Block';
import { ListTable } from '../listTable/ListTable';
import './styles.css';
import { TContent } from './types';

export const MobileContent = ({ id, upPage }: TContent) => {
  const renderLogos = (): React.ReactElement[] => {
    const logosArray: number[] = [1, 2, 3, 4, 5, 6, 7];

    return logosArray.map((itemId) => {
      return <img
        src={"images/" + itemId + ".png"}
        className="logoH"
        key={itemId + "a"}
        alt=""
      />
    })
  };

  useEffect(() => {
    const el = document.getElementById("formid");
    if (el)
      el.addEventListener("submit", (event) => {
        const url = "email.php";
        const request = new XMLHttpRequest();
        request.open("POST", url, true);
        request.onload = function () {
          // request successful
          // we can use server response to our request now
          alert('Письмо успешно отправлено. Спасибо за ваш отзыв!')
        };

        request.onerror = function () {
          alert('Ошибка отправки. Попробуйте вручную.')
        };

        request.send(new FormData(event.target as HTMLFormElement)); // create FormData from form that triggered event
        event.preventDefault();
      });
  }, []);

  const openPrice = useCallback(()=> {
    console.log('x')
    upPage(1)
  },[upPage])

  const getBlock = (id: number): React.ReactElement => {
    switch (id) {
      case 0:
        return (
          <div id="m0">
            <img src="images/tech6.png" className="imageMobile" alt="" />
            <Block title="О компании">
              <p className="text">
              Сервисный центр <b>ИП Сиганьков С.В.</b> осуществляет гарантийный и платный ремонт широкого спектра бытовой радиоэлектронной аппаратуры производства ведущих фирм-изготовителей. 
              На сегодняшний день предприятие осуществляет ремонт следующих категорий бытовой техники: аудио-видео аппаратуры, телевизоров ЖК/Плазма/кинескопных, мониторов, 
              автомагнитол, стиральных машин, холодильников, кондиционеров, микроволновых печей, пылесосов.
              </p>
            </Block>
            <Block title="Режим работы">
              <p className="text">
                Понедельник – пятница : 8.00 – 17.00.
                <br />
                Перерыв 12.00 – 13.00
                <br />
                <br />
                Суббота, Воскресенье: выходные дни.
              </p>
            </Block>
            <Block title="Реквизиты">
              <p className="text">
                ИП Сиганьков Сергей Владимирович
                <br />
                <b>ИНН</b> 683101340782
                <br />
                <b>ОГРНИП</b> 317682000002101
                <br />
                <b>Р/С</b> 40802810226100000834
                <br />
                <b>К/С</b> 30101810000000000201
                <br />
                <b>БИК</b> 044525201
                <br />
                В ПАО АКБ «Авангард»
                <br />
              </p>
            </Block>
            <Block
              title="Гарантийное обслуживание"
              content={`На сегодняшний день предприятие осуществляет гарантийное и бесплатное сервисное обслуживание большинства современных брендов. 
            Список обслуживаемых брендов постоянно расширяется.
            Ремонт производится опытными инженерами предприятия, прошедшими сертификацию у фирм-изготовителей. 
            Все ремонты проводятся профессиональным оборудованием фирм FLUCK, WELLER, WILTEK, LUCKEY, WIGAM. 
            Диагностика аппаратуры производится в процессе ремонта. В случае установления нарушения правил эксплуатации указанных в условиях гарантийного обслуживания, 
            гарантийный ремонт не производится. При приеме неисправной техники в гарантийный ремонт должны быть предоставлены 
            следующие документы: оригинальный гарантийный талон фирмы-изготовителя оформленный должным образом (наличие печати продавца, даты продажи, 
            модели и серийного номера аппарата). В случае отсутствия гарантийного талона у клиента, требуется наличие кассового и товарного чеков, 
            подтверждающих дату продажи товара фирмой продавцом. 
            При отсутствии гарантийного талона и чеков, гарантийный срок исчисляется с даты производства неисправного аппарата, 
            при этом срок бесплатного сервисного обслуживания равен гарантийному сроку.`}
            />
             <Block
              title="Платные ремонты"
              
            >
              <p className='text'>
              Платный ремонт осуществляется предприятием на основании утвержденного <span className="hyperlinkColor" onClick={openPrice} style={{cursor:'pointer'}}>
                  <b> прейскуранта. </b>
                </span> 
            По результатам диагностики принимается решение о ремонтопригодности аппаратуры. 
            Оплата ремонта производится по окончанию выполненных работ. На выполненные работы по ремонту бытовой аппаратуры дается гарантия 3 месяца, 
            выдаются фискальные документы. Предусмотрена работа с юридическими лицами по выставленным счетам и 
            выдачей соответствующих документов (акт выполненных работ, договор на оказание услуг). 
            При выполнении ремонта устраняется только заявленная неисправность. В случае подтверждения факта выхода из строя аппаратуры 
            в результате перенапряжения в электрической сети, оформляется акт технического состояния по результатам ремонта с указанием вероятной причины выхода из строя.
              </p>
            </Block>
            <Block
              title="Вакансии"
              content={`На данный момент открытых вакансий нет.`}
            />
          </div>
        );
      case 1:
        return (
          <div id="m1">
            <Block title="Авторизации">
              <div className="logosContainer">{renderLogos()}</div>
            </Block>
            <Block title="Услуги" style={{ marginBottom: 0 }}>
              <p className="text">
                Ниже предоставлен прейскурант цен на ремонт и техническое
                обслуживание теле-видео-аудио аппаратуры, бытовой техники и
                средств связи на момент 19.02.2020.
                <span className="hyperlinkColor">
                  <b> Внимание!</b>{" "}
                </span>
                Цены могут измениться, а оператор не успеть внести актуальную информацию, поэтому во избежание
                недоразумений уточняйте цены по телефону.
              </p>
              <br />
            </Block>
            <ListTable />
          </div>
        )
      case 2:
        return (
          <div id="m2">
            <Block title="Контакты">
              <p className="text">
                <li>
                  Телефоны: 8 (4752) 45-16-15, 53-72-11
                  <br />
                </li>
                <li>
                  Факс: 8 (4752) 53-42-84
                  <br />
                </li>
                <li>
                  email: rubinsvc@tmb.ru
                  <br />
                  <br />
                </li>
                <li>
                  Как до нас добраться:
                  <br />
                </li>
                {`Из центра по ул.Мичуринская троллейбусом №6, автобусами №7, 8, 14А, 23, 30, 31, 
                маршрутными такси №8, 16, 30, 62 до остановки Ателье "Молодежное" (м."Детский мир"), далее 50 м до первого поворота направо на ул.Рязанская, 
                далее через 200 м третий поворот направо на ул.Н.Вирты. Из центра по улице К.Маркса автобусом № 17 
                до остановки "Гастроном Северный" (м."Копейка"), переход на противоположную сторону дороги на ул.Рязанская по 
                направлению к улице Мичуринская через 150м первый поворот налево на ул. Н.Вирты.`}
              </p>
            </Block>
            <div className="circle-map">
              <iframe
                title="google map Сервисный центр ИП Сиганьков С.В."
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d500.81041998969033!2d41.44059159114054!3d52.74078015186933!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x413916a1b13f4dff%3A0x45ccce30bfde3b88!2z0KDRg9Cx0LjQvSDRgdC10YDQstC40YE!5e0!3m2!1sru!2sru!4v1582143574700!5m2!1sru!2sru"
                width="100%"
                height="200vw"
                frameBorder={0}
                allowFullScreen
              />
            </div>
            <Block title="Написать нам письмо">
              <form method="post" id="formid" style={{paddingTop: '5vw'}}>
                {/* <div className="formElement">
                  <span className="text">Отдел:</span>
                  <select name="Whois" id="Whois">
                    <option>Общие вопросы</option>
                    <option>Сотрудничество</option>
                    <option>Вакансии</option>
                    <option>Благодарность/жалобы</option>
                  </select>
                </div> */}
                <div className="formElement">
                  <span className="text">Ваше имя:</span>
                  <input type="text" maxLength={30} name="_name" />
                </div>
                <div className="formElement">
                  <span className="text">Ваша почта:</span>
                  <input type="text" maxLength={30} name="_email" />
                </div>
                <div className="formElement">
                  <span className="text">Тема:</span>
                  <input type="text" maxLength={30} name="_theme" />
                </div>
                <textarea
                  wrap="physical"
                  name="_message"
                  placeholder={"Ваше сообщение"}
                />
                <div className="buttonSendContainer">
                  <button
                    type="submit"
                    value="ОТПРАВИТЬ"
                    className="inputbtn"
                  >
                  Отправить
                  </button>
                </div>
              </form>
            </Block>
          </div>
        );
      default: return <div />
    }
  };

  return (
    <div className="content">
      {getBlock(id)}
    </div>
  );
};

import React, { useEffect, useImperativeHandle } from "react";
import { ReactComponent as Logo } from "../../svg/logo.svg";
import gsap from "gsap";
import './styles.css';

interface IProps {
  click : (id:number) => void
}

export const WebHeader = React.forwardRef<any, IProps>(({ click }, ref) => {

  useImperativeHandle(ref, () => ({
    outer: (id: number) => {
       clickHandler(id);
    },
}));

  useEffect(() => {
    gsap.to(".logoOver", { scale: 2, duration: 1, opacity: 0, repeat: -1, delay: 1.1 });
    gsap.to("#b0", { duration: 0, scale: 1,color:'#00eeff' }); 
  }, [])

  const clickHandler = (id: number) => {
    click(id);
    gsap.to("#b0", { duration: 0.5, scale: 1, color:'#fff' }); 
    gsap.to("#b1", { duration: 0.5, scale: 1,color:'#fff' }); 
    gsap.to("#b2", { duration: 0.5, scale: 1,color:'#fff' }); 
    gsap.to("#b" + id, { duration: 0.5, scale: 1, color:'#00eeff' }); 
  }

  return (
    <header className="webHeader">
      <div className="container">
        <div className="logoContainer">
          <Logo className="logo" />
          <Logo className="logoOver" />
          <div className="logoName">
            <h1>Сервисный центр</h1>
          </div>
        </div>
        <div className="buttonsContainer">
          <button className="menuButton" id="b0" onClick={() => clickHandler(0)}>
            {"Главная"}
          </button>
          <button className="menuButton" id="b1" onClick={() => clickHandler(1)}>
            {"Услуги"}
          </button>
          <button className="menuButton" id="b2" onClick={() => clickHandler(2)}>
            {"Контакты"}
          </button>
        </div>
      </div>
    </header>
  )
})

import React from 'react';
import './styles.css';

export const MobileFooter = () => {
  return (
    <footer className="footer">
      <div className="line" />
      <div className="text">
        <h1>"Сервисный центр ИП Сиганьков С.В." Created and designed by in4core studios llc © 2000-2020</h1>
      </div>
    </footer>
  )
}
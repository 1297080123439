import React, { useEffect, useImperativeHandle, useState } from "react";
import { ReactComponent as Logo } from "../../svg/logo.svg";
import gsap from "gsap";
import './styles.css';

import { MdMoreHoriz } from 'react-icons/md';
import { MdClose } from 'react-icons/md';
import { MdChevronRight } from 'react-icons/md';
import { THeaderProps } from './types';

interface IProps {
  click : (id:number) => void
}


export const MobileHeader = React.forwardRef<any, IProps>(({ click }: THeaderProps, ref) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [currentSection, setCurrentSection] = useState(0);
  const [scrollPos, setScrollPos] = useState(0);

  useImperativeHandle(ref, () => ({
    outer: (id: number) => {
      setCurrentSection(id);
    },
}));

  const menuOpenHandler = (): void => {
    setMenuOpen(prev => !prev);
  }

  useEffect(() => {
    gsap.to(".mobileHeader", {
      height: menuOpen ? '100vh' : '20vw',
      backgroundColor: menuOpen ? '#006699' : '#004477',
      duration: 0.3
    })

    gsap.to(".logo", {
      scale: menuOpen ? 4 : 1,
      rotate: menuOpen ? -360 : 0,
      x: menuOpen ? window.innerWidth / 2 : 0,
      y: menuOpen ? '20vh' : 0,
      xPercent: menuOpen ? -100 : 0,
      yPercent: menuOpen ? -100 : 0,
    })

    gsap.to(".logoName", {
      x: menuOpen ? window.innerWidth / 2 : 0,
      xPercent: menuOpen ? -80 : 0,
      y: menuOpen ? '35vh' : 0,
      scale: menuOpen ? 1.5 : 1,
      textAlign: menuOpen ? 'center' : 'left'
    });

    gsap.killTweensOf(".buttonsContainer");
    gsap.to(".buttonsContainer", { visibility: menuOpen? 'visible' : 'hidden', duration: 0 });

    gsap.to(".buttonsContainer", {
      opacity: menuOpen ? 1 : 0,
      y: menuOpen ? '45vh' : '80vh',
    })

    if (menuOpen) {
      setScrollPos(window.pageYOffset || document.documentElement.scrollTop)
      setTimeout(() => document.body.setAttribute('style', 'overflow: hidden'), 500);
    }
    else {
      document.body.setAttribute('style', 'overflow: null')
      window.scrollTo({ top: scrollPos })
    }
  }, [menuOpen, scrollPos]);

  useEffect(() => {
    setMenuOpen(false);
    click(currentSection);
    window.scrollTo({ top: 0 })
  }, [currentSection, click]);

  useEffect(() => {
    if (!menuOpen) {
      gsap.killTweensOf(".logoOver");
      gsap.to(".logoOver", { visibility: 'visible', scale: 1, opacity: 0.5, duration: 0, delay: 1 });
      gsap.to(".logoOver", { scale: 2, duration: 1, opacity: 0, repeat: -1, delay: 1.1 });
    } else {
      gsap.killTweensOf(".logoOver");
      gsap.to(".logoOver", { visibility: 'hidden', duration: 0 });
    }
  }, [menuOpen])

  return (
    <header className="mobileHeader" id="mobile_header">
      <div className="container">
        <div className="logoContainer">
          <Logo className="logo" />
          <Logo className="logoOver" />
          <div className="logoName">
            <h1>Сервисный центр</h1>
            <h2>{menuOpen ? 'ИП Сиганьков Сергей Владимирович' : 'обслуживание и ремонт бытовой техники'}</h2>
          </div>
        </div>
        <button className="menuMainButton" onClick={menuOpenHandler}>
          {!menuOpen
            ? <MdMoreHoriz color='#fff' size={'9vw'} />
            : <MdClose color='#fff' size={'9vw'} />
          }
        </button>
      </div>
      <div className="buttonsContainer">
        <button
          className="menuButton"
          onClick={() => setCurrentSection(0)}
          style={{ backgroundColor: currentSection === 0 ? 'rgba(255,255,255,0.2)' : 'rgba(255,255,255,0.05)' }}>
          <h1>Главная</h1>
          {currentSection !== 0 && <MdChevronRight color='#fff' size={'9vw'} />}
        </button>
        <button
          className="menuButton"
          onClick={() => setCurrentSection(1)}
          style={{ backgroundColor: currentSection === 1 ? 'rgba(255,255,255,0.2)' : 'rgba(255,255,255,0.05)' }}>
          <h1>Услуги</h1>
          {currentSection !== 1 && <MdChevronRight color='#fff' size={'9vw'} />}
        </button>
        <button
          className="menuButton"
          onClick={() => setCurrentSection(2)}
          style={{ backgroundColor: currentSection === 2 ? 'rgba(255,255,255,0.2)' : 'rgba(255,255,255,0.05)' }}>
          <h1>Контакты</h1>
          {currentSection !== 2 && <MdChevronRight color='#fff' size={'9vw'} />}
        </button>
      </div>
      <div className="bottomCopy" style={{ opacity: menuOpen ? 0.5 : 0 }}>
        <h2>
          Created and designed by in4core-studios-llc 2020
        </h2>
      </div>
    </header>
  );
});

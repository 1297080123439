import React, { useCallback, useEffect, useRef, useState } from "react";
import { BrowserView, MobileView, isTablet, isMobile } from 'react-device-detect';
import gsap from 'gsap';
import smoothscroll from 'smoothscroll-polyfill';
import "./styles/baseStyles.css";
import { MobileHeader } from './components/mobileHeader/MobileHeader';
import { WebHeader } from './components/webHeader/WebHeader';
import { MobileFooter } from './components/mobileFooter/MobileFooter';
import { MobileContent } from './components/content/mobileContent/MobileContent';
import { WebContent } from './components/content/webContent/WebContent';
import { MdCall } from 'react-icons/md';
import { WebFooter } from './components/webFooter/WebFooter';

function App() {
  const [section, changeSection] = useState(0);
  

  const updatePage = (id: number) => {
    if (id === section) return;
    if(isMobile && !isTablet) return;
    window.scrollTo({ top: 0 });
    gsap.to(".web", { opacity: 0, y: 500, duration: 0.2, onComplete: () => changeSection(id) });
  }

  useEffect(() => {
    if(isMobile && !isTablet) return;
    gsap.fromTo(".web", { opacity: 0, y: -500, duration: 0 }, { opacity: 1, y: 0, duration: 0.5 });
  }, [section]);

  const onChange = (e: any) => {
   
  }

  useEffect(() => {
    smoothscroll.polyfill();
    window.addEventListener('orientationchange', onChange);

    if(isMobile && !isTablet) {
      gsap.to('.callBack', { duration: 1, scale: 1.2, opacity: 0, repeat: -1 });
      gsap.to('.call', { duration: 0.5, scale: 0.9, repeat: -1, yoyo: true })
    }
  }, []);

  const refO = useRef<any>(null);

  const uRefo = useCallback((id)=> {
    if(refO.current) {
      refO.current.outer(id)
    }
  },[])

  return (
    <div className="app">
      <MobileView>
        {!isTablet?
          <>
            <a href="tel:84752451615">
              <div className="callButtonContainer" >
                <div className="callButton">
                  <div className="callBack" />
                  <MdCall color='#fff' size={'9vw'} className="call" />
                </div>
              </div>
            </a>
            <MobileHeader click={(id) => changeSection(id)} ref={refO}/>
            <div className="contentWrapper">
              <MobileContent id={section} upPage={uRefo} />
            </div>
            <MobileFooter />
          </> : 
          <>
            <WebHeader click={(id) => updatePage(id)} ref={refO}/>
              <div className="web">
                <WebContent id={section} upPage={uRefo}/>
              </div>
            <WebFooter />
          </>
        }
      </MobileView>
      
      <BrowserView>
        <WebHeader click={(id) => updatePage(id)} ref={refO}/>
        <div className="web">
          <WebContent id={section} upPage={uRefo}/>
        </div>
        <WebFooter />
      </BrowserView>
    </div>
  );
}

export default App;

import React from 'react';
import { TBlock } from './types';
import './styles.css';

export const Block = ({ title, content, children, id, style }: React.PropsWithChildren<TBlock>) => {
  const bSpan = title && <span style={{ color: '#0088ee' }}>{title.substr(0, 1)}</span>

  return (
    <div className="block" id={id} style={style}>
      {title && <div className="header">{bSpan}{title.substr(1)}</div>}
      {content && <p className="text">{content}</p>}
      {children}
    </div>
  )
}